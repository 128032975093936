import React from 'react'
import './newNav.css'

const newNav = () => {
  return (
    <div className='new_nav'>
      <div className="nav_data">
        <div className="left_data">
            <p>All</p>
            <p>BestSeller</p>
            <p>Mobile</p>
            <p>Electronics</p>
            <p>Laptops</p>
            <p>Fashion</p>
            <p>Clothes</p>
        </div>
        <div className="right_data">
        
        </div>
      </div>
    </div>
  )
}

export default newNav
